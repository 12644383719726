import {
  Box,
} from '@chakra-ui/react';

const NotFound = () => {
  return (
    <Box>
      Page not found.
    </Box>
  )
}

export default NotFound;
